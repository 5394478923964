import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { LOGOUT, ENGINEER_LOGOUT } from "@/core/services/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import JwtService from "@/core/services/jwt.service";
export default {
  name: "KTQuickUser",
  data: function data() {
    return {
      list: [],
      CurrentUser: new Object(),
      userChannel: "customer"
    };
  },
  mounted: function mounted() {
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
    this.getUserProfile();
  },
  methods: {
    onLogout: function onLogout() {
      var _this2 = this;

      if (this.userChannel === "engineer") {
        this.$store.dispatch(ENGINEER_LOGOUT).then(function () {
          return _this2.$router.push({
            name: "engineer.login"
          });
        });
      } else {
        this.$store.dispatch(LOGOUT).then(function () {
          return _this2.$router.push({
            name: "admin.login"
          });
        });
      }
    },
    closeOffcanvas: function closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },
    getUserProfile: function getUserProfile() {
      var _this = this;

      try {
        _this.CurrentUser = JwtService.currentUser();

        if (_this.lodash.isEmpty(_this.CurrentUser) === false && _this.lodash.isObject(_this.CurrentUser)) {
          _this.userChannel = _this.lodash.toString(_this.CurrentUser.channel);
        } else {
          _this.onLogout();
        }
      } catch (error) {
        _this.logError(error);

        _this.onLogout();
      }
    }
  },
  computed: {
    getProfileImage: function getProfileImage() {
      if (this.lodash.isEmpty(this.CurrentUser) === false && this.lodash.isEmpty(this.CurrentUser.profile_logo) === false) {
        return this.CurrentUser.profile_logo.file.url;
      }

      return null;
    }
  }
};