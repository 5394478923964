import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { SearchEventBus } from "@/core/lib/search.lib";
export default {
  data: function data() {
    return {
      isFocused: false,
      searchModel: null
    };
  },
  props: {
    searchTitle: {
      type: String,
      default: ""
    }
  },
  methods: {
    onEsc: function onEsc() {
      this.resetSearch();
    },
    onEnter: function onEnter() {
      this.startSearch();
    },
    onTab: function onTab() {
      this.startSearch();
    },
    startSearch: function startSearch() {
      SearchEventBus.$emit("start-search", this.searchModel);
    },
    resetSearch: function resetSearch() {
      var _this = this;

      _this.$nextTick(function () {
        _this.searchModel = null;
        _this.isFocused = false;
      });
    }
  },
  beforeDestroy: function beforeDestroy() {
    document.onkeydown = null;
    this.resetSearch(); // SearchEventBus.$off("search-template");
  },
  beforeCreate: function beforeCreate() {
    var _this = this;

    SearchEventBus.$on("search-template", function (argument) {
      if (!argument) {
        _this.resetSearch();
      }
    });
  },
  mounted: function mounted() {
    var _this = this;

    if (_this.lodash.isEmpty(_this.$route.query) === false && _this.$route.query.search) {
      _this.searchModel = _this.$route.query.search;

      _this.startSearch();

      _this.$refs.search.focus();
    }
  },
  computed: {
    placeholder: function placeholder() {
      return this.isFocused ? "Start Typing then press enter" : "Search " + (this.searchTitle === "Engineer" ? "Staffs" : this.searchTitle) + "...";
    },
    isSearching: function isSearching() {
      return this.searchModel && this.searchModel.length > 0;
    }
  }
};