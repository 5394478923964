import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import JwtService from "@/core/services/jwt.service";
import inflect from "i";
export default {
  name: "KTMenu",
  data: function data() {
    return {
      MenuList: []
      /* NewMenu:[
        {
          group:null,
          icon:"property",
          menu:"Leave",
          order:1,
          route:{
            name:"admin.leave",
            query:{
              status:"all"
            }
          },
          title:"Leave"
        },
        {
          group:null,
          icon:"property",
          menu:"Holiday",
          order:1,
          route:{
            name:"admin.holiday",
            query:{
              status:"all"
            }
          },
          title:"Holiday"
        }
      ],*/

    };
  },
  methods: {
    getMenuTitle: function getMenuTitle(row) {
      if (!row) {
        return null;
      }

      if (row.menu != "dashboard" && row.menu != "calendar") {
        return inflect().pluralize(row.title);
      } else {
        return row.title;
      }
    },
    isChildActive: function isChildActive(row) {
      var isActive = false;

      for (var i = 0; i < row.length; i++) {
        isActive = this.isRouteActive(row[i].menu);

        if (isActive) {
          return isActive;
        }
      }

      return isActive;
    },
    hasActiveChildren: function hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    isRouteActive: function isRouteActive(match) {
      if (this.lodash.isEmpty(this.$route.meta) === false) {
        return this.$route.meta.menuActive === match;
      }

      return false;
    },
    disableLink: function disableLink(match) {
      if (this.lodash.isEmpty(this.$route.meta) === false) {
        return this.$route.meta.menuActive === match && this.$route.meta.isListing;
      }

      return false;
    },
    getGroupTitle: function getGroupTitle(row) {
      for (var i = 0; i < row.length; i++) {
        if (row[i].group) {
          return row[i].group;
        }
      }
    },
    getMenuList: function getMenuList() {
      var _this = this;

      try {
        var result = JwtService.menuList();
        var arrays = [];
        var objects = [];
        var topTwo = [];
        var lastOne = [];

        for (var menu in result) {
          if (_this.lodash.isArray(result[menu])) {
            arrays.push(result[menu]);
          } else if (_this.lodash.isObject(result[menu]) && result[menu].order <= 1) {
            topTwo.push(result[menu]);
          } else if (_this.lodash.isObject(result[menu]) && result[menu].order >= 21) {
            lastOne.push(result[menu]);
          } else if (_this.lodash.isObject(result[menu])) {
            objects.push(result[menu]);
          }
        }

        _this.MenuList = [].concat(topTwo, arrays, objects, lastOne);

        if (_this.lodash.isEmpty(_this.MenuList)) {
          JwtService.destroyToken();
        }
      } catch (error) {
        _this.logError(error);

        JwtService.destroyToken();
      }
    }
  },
  mounted: function mounted() {
    this.getMenuList();
  }
};