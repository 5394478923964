import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import moment from "moment-timezone";
import Pusher from "pusher-js";
import CommonMixin from "@/core/plugins/common-mixin";
import { GET } from "@/core/services/store/request.module";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);
export default {
  mixins: [CommonMixin],
  name: "KTDropdownNotification",
  data: function data() {
    return {
      tabIndex: 0,
      currentUser: null,
      notification: [],
      logs: []
    };
  },
  mounted: function mounted() {
    this.getNotifications();

    if (this.lodash.isEmpty(this.currentUser) === false) {
      this.subscribe();
    }
  },
  created: function created() {
    this.currentUser = this.getCurrentUser();
  },
  methods: {
    pushToRoute: function pushToRoute(item) {
      if (item.route) {
        this.$router.push(this.getDefaultRoute(item.route.name, {
          params: item.route.params
        }));
      }
    },
    isNewNotification: function isNewNotification(date) {
      if (date) {
        var newDate = moment().add(1, "days");
        return moment(newDate).isAfter();
      }

      return false;
    },
    getNotifications: function getNotifications() {
      var _this = this;

      _this.$store.dispatch(GET, {
        url: "notifications"
      }).then(function (_ref) {
        var data = _ref.data;
        _this.notification = data;
      }).catch(function (error) {
        _this.logError(error);
      });
    },
    subscribe: function subscribe() {
      var _this = this;

      var user_id = _this.lodash.toSafeInteger(_this.currentUser.id);

      var pusher = new Pusher(process.env.VUE_APP_PUSHER_API_KEY, {
        cluster: "ap2",
        encrypted: true
      });
      pusher.subscribe("genic.app.notifications." + user_id);
      pusher.bind("App\\Events\\GenicAppNotifications", function (response) {
        _this.notification.unshift(response.data);
      });
    },
    setActiveTab: function setActiveTab(event) {
      var tab = event.target.closest('[role="tablist"]');
      var links = tab.querySelectorAll(".nav-link"); // remove active tab links

      for (var i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      } // set clicked tab index to bootstrap tab


      this.tabIndex = parseInt(event.target.getAttribute("data-tab")); // set current active tab

      event.target.classList.add("active");
    }
  },
  computed: {
    backgroundImage: function backgroundImage() {
      return process.env.VUE_APP_BASE_URL + "media/misc/bg-1.jpg";
    },
    totalNotifications: function totalNotifications() {
      var notificationCount = this.notification.length;
      var logsCount = this.logs.length;
      return this.lodash.toSafeInteger(notificationCount) + this.lodash.toSafeInteger(logsCount);
    }
  }
};