import _objectSpread from "E:/AirVenture/frontend/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { QUERY } from "@/core/services/store/request.module";
import { mapGetters } from "vuex"; //import objectPath from "object-path";

import KTLayoutBrand from "@/assets/js/layout/base/brand.js";
import KTLayoutAsideToggle from "@/assets/js/layout/base/aside-toggle.js";
export default {
  name: "KTBrand",
  data: function data() {
    return {
      detail: {
        company_logo: null
      }
    };
  },
  mounted: function mounted() {
    // Init Brand Panel For Logo
    KTLayoutBrand.init(this.$refs["kt_brand"]); // Init Aside Menu Toggle

    KTLayoutAsideToggle.init(this.$refs["kt_aside_toggle"]);

    var _toggleObject = KTLayoutAsideToggle.getToggle();

    var _toggleState = localStorage.getItem("kt_aside_toggle_state");

    this.siteLogo();

    if (_toggleState == "on") {
      _toggleObject.toggleOn();
    }
  },
  methods: {
    siteLogo: function siteLogo() {
      var _this = this;

      _this.detail = new Object({
        company_logo: null
      });

      _this.$store.dispatch(QUERY, {
        url: "setting/branding",
        data: {
          entity: 1
        }
      }).then(function (_ref) {
        var data = _ref.data;
        _this.detail = data;
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.pageLoading = false;
      }); // const menuAsideLeftSkin = this.layoutConfig("brand.self.theme");
      // // set brand logo
      // const logoObject = this.layoutConfig("self.logo");
      // let logo;
      // if (typeof logoObject === "string") {
      //   logo = logoObject;
      // }
      // if (typeof logoObject === "object") {
      //   logo = objectPath.get(logoObject, menuAsideLeftSkin + "");
      // }
      // if (typeof logo === "undefined") {
      //   const logos = this.layoutConfig("self.logo");
      //   logo = logos[Object.keys(logos)[0]];
      // }
      // return process.env.VUE_APP_BASE_URL + logo;

    }
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(["layoutConfig"])), {}, {
    allowMinimize: function allowMinimize() {
      return !!this.layoutConfig("aside.self.minimize.toggle");
    }
  })
};